<template>

<div class="animated fadeIn">
  <div id="snackbar"></div>
 <!-- List of programming -->
    <b-card>
       <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
         <!-- <div slot="CinemaScheduleID" slot-scope="props">
            <i class="fa fa-edit" @click="editProg(props.row.CinemaScheduleID)"></i>
          </div> -->
          <div slot="ScheduleIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.ScheduleIsActive" variant="3d" @change.native="changeStatus(props.row.ScheduleID)" label v-bind="labelIcon" />             
          </div>
        </v-client-table>
    </b-card>
    <!-- End List -->
</div>

</template>
<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import CinemaService from "@/services/CinemaService";
import MasterService from "@/services/MasterService";

Vue.use(ClientTable);
export default {
  name: "ListOfProgramming",
  components: {
    cSwitch,
    ClientTable,
    Event
  },

  data() {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      data: [],
      columns: [
        "CinemaName",
        "ScreenName",
        "MovieName",
        "Date",
        "ShowTime",
        "FormatAndLanguage",
        "ScheduleIsActive"

        // "FromDateSch",
        // "ToDateSch",
        // // "SeatType",
        // // "SeatPrice",
        // "CinemaTimings",
      ],

      options: {
        headings: {
          CinemaName: "Cinema Name",
          ScreenName: "Screen Name",
          MovieName: "Movie Name",
          Date: "Date",
          ShowTime: "Show Time",
          FormatAndLanguage: "Format And Language",
          ScheduleIsActive: "Status"

          // FromDateSch: "From Date",
          // ToDateSch: "To Date",
          // // SeatType: "Seat Type",
          // // SeatPrice: "Seat Price",
          // CinemaTimings: "Cinema Timing"
        },
        sortable: [
          "CinemaName",
          "ScreenName",
          "MovieName",
          "Date",
          "ShowTime",
          "FormatAndLanguage",
          "ScheduleIsActive"
        ],
        filterable: [
          "CinemaName",
          "ScreenName",
          "MovieName",
          "Date",
          "ShowTime",
          "FormatAndLanguage",
          "ScheduleIsActive"
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getProgramming();
  },
  methods: {
    getProgramming: function() {
      this.data = [];
      // let res = await CinemaService.getCinemaScheduleList();
      this.data = [];
      CinemaService.getCinemaScheduleList()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaScheduleList.length > 0) {
            this.data = data.CinemaScheduleList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editProg: function(CinemaScheduleID) {
      this.$router.push({
        path: "/programming/add-programming",
        query: { CinemaScheduleID }
      });
    },
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "CinemaSchedule");
      let payload = {
        TableName: "CinemaSchedule",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          this.getProgramming();
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
